@layer modules, ui, base;
@layer base {
  .Brand_app-Brand__c4Qbx {
  display: flex;
  align-items: center;
  margin: 0 var(--sk-space-16) 0 var(--sk-space-24);
}

.Brand_app-Brand--footer__W59hv {
  margin-bottom: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .Brand_app-Brand--footer__W59hv {
    margin-bottom: 0;
  }
}

@media (min-width: 64rem) {
  .Brand_app-Brand__c4Qbx {
    margin: 0;
  }
}

/******************************
  JT LOGO
*******************************/

.Brand_app-JtLogo__lRH5S {
  width: auto;
  height: 1.5rem;
}

@media (min-width: 48rem) {
  .Brand_app-JtLogo___footer__7MXeC {
    height: 2rem;
  }
}

@media (min-width: 64rem) {
  .Brand_app-JtLogo___header__EE3FC {
    height: 2rem;
  }
}

/******************************
  CC LOGO
*******************************/

.Brand_app-Brand__CcImage__DidkI {
  width: var(--image-width);
  border-radius: var(--image-border-radius);
}

.Brand_app-Brand__CcImage__DidkI:last-of-type {
  margin-right: var(--image-spacing);
}

.Brand_app-Brand__CcImage__DidkI + .Brand_app-Brand__CcImage__DidkI {
  margin-left: var(--image-spacing);
}

.Brand_app-Brand__CcTitleGroup___rWb3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--titleGroup-gap);
  margin-right: var(--titleGroup-margin-right);
}

.Brand_app-Brand__CcTitle__zWSQT {
  color: currentcolor;
  font-family: var(--fontHeading);
  font-size: var(--title-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.7px;
  line-height: var(--title-size);

  /* the name of the Career Center is displayed on a specific maximum number of lines and truncated with ellipsis beyond that */
  /* stylelint-disable-next-line order/properties-order */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--title-line-clamp);
  line-clamp: var(--title-line-clamp);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Brand_app-Brand__CcSubtitle__a3OOB {
  width: auto;
  height: var(--subtitle-height);
}

.Brand_app-Brand__CcLogo__SvY8S {
  /* Style specific to this element */
  display: flex;
  align-items: center;

  /* 
    We define custom properties for the two CC logo formats: small and big.
    They will be used by children of this element to adapt their styles.
  */

  --small-image-width: 24px;
  --big-image-width: 48px;

  --small-image-border-radius: var(--sk-radius-2);
  --big-image-border-radius: var(--sk-radius-4);

  --small-image-spacing: var(--sk-space-4);
  --big-image-spacing: var(--sk-space-8);

  --small-titleGroup-gap: var(--sk-space-2);
  --big-titleGroup-gap: var(--sk-space-4);

  --small-titleGroup-margin-right: 0;
  --big-titleGroup-margin-right: var(--sk-space-8);

  --small-title-size: 14px;
  --big-title-size: 16px;

  --small-title-line-clamp: 1;
  --big-title-line-clamp: 2;

  --small-subtitle-height: 9px;
  --big-subtitle-height: 10px;

  /* Mobile style for header and footer: small */

  --image-width: var(--small-image-width);
  --image-border-radius: var(--small-image-border-radius);
  --image-spacing: var(--small-image-spacing);

  --titleGroup-gap: var(--small-titleGroup-gap);
  --titleGroup-margin-right: var(--small-titleGroup-margin-right);

  --title-size: var(--small-title-size);
  --title-line-clamp: var(--small-title-line-clamp);

  --subtitle-height: var(--small-subtitle-height);
}

/* Tablet style for footer: big */

@media (min-width: 48rem) {
  .Brand_app-Brand__CcLogo___footer__HJoI6 {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

/* Desktop style for header and footer: big */

@media (min-width: 64rem) {
  .Brand_app-Brand__CcLogo__SvY8S {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

}
@layer base {
  .Icon_hdr-Icon__KJAoi {
  --iconSize: 20px;
  width: var(--iconSize);
  min-width: var(--iconSize);
  height: var(--iconSize);
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_hdr-Icon--isBig__nDZPe {
  --iconSize: 24px;
}

}
@layer base {
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
  font-size: 1rem;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
  display: flex;
  position: relative;
  outline: none;
}

@media (max-width: 767px) {
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
    justify-content: center;
  }
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within {
  pointer-events: none;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  font-family: var(--fontFamily);
  white-space: nowrap;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
.LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  position: absolute;
  top: 100%;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: 8px;
  clip: auto;
  background: var(--sk-color-white);
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph:hover {
  background-color: var(--sk-color-grey-025);
}

.LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  display: flex;
  align-items: center;
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 {
  padding: 0.8125rem 0.75rem;
  border-radius: var(--sk-radius-4);
  background-color: var(--sk-color-white);
  color: var(--sk-color-grey-500);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  padding-right: 0.5rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkText__5T4xT {
  margin-left: 12px;
  color: var(--sk-color-grey-600);
  font-size: 0.75rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.25rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
  display: flex;
  align-items: center;
  margin: 0;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX:focus-within,
.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  background-color: var(--sk-color-grey-025);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh {
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    width: 100%;
    padding: 1.125rem 1.875rem;
    border: thin solid var(--sk-color-grey-200);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU,
  .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    flex: 1;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: -300px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 0;
    overflow-y: auto;
    border: thin solid var(--sk-color-grey-100);
    box-shadow: var(--shadow-default);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: inherit;
    bottom: calc(100% + 1px);
    height: auto;
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border-bottom: thin solid var(--sk-color-grey-100);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 16px 20px;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    box-sizing: border-box;
    width: 345px;
    max-height: 50vh;
    overflow-y: visible;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    display: none;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    width: inherit;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    display: flex;
    top: inherit;
    right: 0;
    bottom: calc(100% + 1px);
    flex-wrap: wrap;
    width: auto;
    padding: 8px;
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    box-sizing: border-box;
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
    padding: 12px 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    display: flex;
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
    cursor: pointer;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s {
  color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s::after {
  background-color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  border-radius: var(--sk-radius-8);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    border-radius: var(--sk-radius-8);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    border-radius: var(--sk-radius-8);
  }
}

}
@layer base {
  /* those two rules because Prettier is set to 120 and break some stylelint rules around line 260 */
/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable indentation */
.DropdownMenu_hdr-DropdownMenu__ujkQp {
  display: inline-flex;
  font-size: 1rem;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE {
  display: flex;
  outline: none;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within {
  pointer-events: none;
}

.DropdownMenu_hdr-DropdownMenu-link__DatIX {
  display: flex;
  position: relative;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-family: var(--fontFamily);
  font-size: var(--smallBody);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.DropdownMenu_hdr-DropdownMenu-link__DatIX::after,
.DropdownMenu_hdr-DropdownMenu-link__DatIX .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-link__DatIX:hover::after,
.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4::after,
.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: var(--sk-space-12);
  width: calc(100% - var(--sk-space-24));
  height: 0.125rem;
  margin: 0;
  padding: 0;
  background-color: var(--themeButtonTextColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE.dropdown--open .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__menu__Jbdcn,
.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE:not(.dropdown--open) .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__cross__n7Gro {
  display: none;
}

.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE.dropdown--open .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__cross__n7Gro,
.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE:not(.dropdown--open) .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__menu__Jbdcn {
  display: block;
}

.DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  flex-direction: column;
  width: 1px;
  height: 1px;
  gap: var(--sk-space-8);
  margin: var(--sk-space-8) 0 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border-radius: var(--sk-radius-4);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: var(--sk-space-16);
  clip: auto;
  background: var(--sk-color-white);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-8);
  color: inherit;
  text-decoration: none;
}

.DropdownMenu_hdr-DropdownMenu-submenuLink--active__FTAy4 p {
  font-weight: var(--sk-font-weights-poppins-3);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM:hover {
  background-color: var(--sk-color-grey-025);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink--withIcon__YljKH svg {
  width: var(--sk-size-20);
  min-width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.DropdownMenu_hdr-DropdownMenu-divider__hONbo {
  display: flex;
}

.DropdownMenu_hdr-DropdownMenu-submenuLinkIcon__A1nH5 {
  padding: 0 1rem 0 0.5rem;
  stroke: var(--themeMainColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-submenuLogo__HFkce {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  border-radius: var(--sk-radius-4);
}

.DropdownMenu_hdr-DropdownMenu-linkTextWrapper__QxG8B {
  margin: 0;
  font-weight: 400;
}

.DropdownMenu_hdr-DropdownMenu-linkTitle__VBqgQ {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownMenu_hdr-DropdownMenu-linkTitle--active__1m__Y {
  font-weight: var(--font-weight-bold);
}

.DropdownMenu_hdr-DropdownMenu-linkText__aGQ5o {
  margin: 0;
  color: var(--sk-color-grey-500);
  font-size: 0.75rem;
  line-height: 1rem;
}

.DropdownMenu_hdr-DropdownMenu-linkIcon__EG__u {
  display: flex;
  align-items: center;
}

.DropdownMenu_hdr-DropdownMenu-item--user__vytk9 {
  font-weight: var(--font-weight-regular);
}

.DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 .DropdownMenu_hdr-DropdownMenu-link__DatIX {
  font-weight: var(--font-weight-bold);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .DropdownMenu_hdr-DropdownMenu-linkLabel--hidden__eOlSw {
    display: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    display: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9 .DropdownMenu_hdr-DropdownMenu-link__DatIX::after {
    content: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9 .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs,
  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    --submenuSpacer: 2vw;
    top: var(--sk-space-56);
    left: var(--submenuSpacer);
    box-sizing: border-box;
    width: calc(100vw - var(--submenuSpacer) * 2);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .DropdownMenu_hdr-DropdownMenu__ujkQp {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 {
    position: relative;
    height: var(--sk-space-64);
  }

  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13::after,
  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    margin: 0;
    padding: 0;
    background-color: var(--sk-color-black);
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    box-sizing: border-box;
    width: 345px;
  }

  .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .DropdownMenu_hdr-DropdownMenu-linkIcon__EG__u {
    display: flex;
    padding-right: 0.5rem;
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-link__DatIX:not(.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4)::after,
  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within
    .DropdownMenu_hdr-DropdownMenu-link__DatIX:not(.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4)
    .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    /* stylelint-disable-line max-line-length */
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link__DatIX {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link__DatIX:hover::after,
[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4::after {
  background-color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs,
[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenuLinkIcon__A1nH5 {
  stroke: var(--sk-color-black);
}

}
@layer base {
  .UserAvatar_userAvatar--default___OA31 {
  background-color: var(--sk-color-grey-100);
  color: var(--sk-color-black);
}

.UserAvatar_userAvatar--image__qvVLz {
  -o-object-fit: cover;
     object-fit: cover;
}

}
@layer base {
  .LoginButton_main__uud3M {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent; /* button reset */
  color: var(--sk-color-black);
  font-family: var(--fontFamily);
  font-size: var(--sk-font-size-1); /* 14px */
  line-height: var(--sk-line-heights-5); /* 16px */
  white-space: nowrap;
  cursor: pointer;
}

/* The login button is visible when the nav is displayed in the burger menu (mobile/tablet) */
.LoginButton_main___inNav__a3ZZs {
  width: 100%;
  margin: 0 var(--sk-space-12);
}

@media (min-width: 64rem) {
  /* --large-viewport */
  /* The login button is hidden when the nav is displayed in the website header (desktop) */
  .LoginButton_main___inNav__a3ZZs {
    display: none;
  }
}

.LoginButton_icon__LXIqs {
  display: flex;
  align-items: center;
}

.LoginButton_label__Agi_d {
  margin-left: 0.5rem;
}

.LoginButton_label___visibleOnDesktopOnly__6As_L {
  /* screen reader only */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

@media (min-width: 64rem) {
  /* disable screen reader only */
  .LoginButton_label___visibleOnDesktopOnly__6As_L {
    position: initial;
    width: initial;
    height: initial;
    padding: initial;
    overflow: initial;
    clip: initial;
    clip-path: initial;
    border: initial;
    white-space: initial;
  }
}

}
@layer base {
  .Network_container__yLIzl a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.Network_filters__DFLfT {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.Network_grid__TrWY3 {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.Network_image__ch_cp {
  border-radius: var(--radius-200);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 64rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 90rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Network_grid__withError__4IZ8a {
  grid-template-columns: none;
}

}
@layer base {
  .Title_title__AcGZQ {
  margin: 0 0 var(--spacer-400);
}

@media (min-width: 48rem) {
  .Title_title__AcGZQ {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .Title_title--small__tCfUd {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.FilterBar_filterBar__SW2X9 [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (min-width: 48rem) {
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}
@layer base {
  .NewsletterContact_label__K2tQ3 {
  color: var(--color-white);
}

@media (max-width: 63.999rem) {
  .NewsletterContact_form___i_DB {
    grid-column: 1;
    margin-top: var(--spacer-300);
  }
}

@media (min-width: 48rem) {
  .NewsletterContact_fieldsWrapper__cazIz {
    display: flex;
    gap: var(--spacer-150);
    align-items: flex-end;
  }

  .NewsletterContact_field__E3D3G {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

}
@layer base {
  .ColGroup_featuresCol__ND0wD {
  width: 23.18%;
}

.ColGroup_packCol--limited__yfGel {
  width: 15.54%;
}

.ColGroup_packCol--light__74tlN,
.ColGroup_packCol--classic__sU67X {
  width: 20.44%;
}

.ColGroup_packCol--performance__qWTab {
  max-width: 20.4%;
}

}
@layer base {
  .IconPackState_main__EEmY6 {
  --jt-IconPackState-size: 1.75rem;

  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: var(--jt-IconPackState-size);
  height: var(--jt-IconPackState-size);
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  font-size: 1rem;
}

.IconPackState_main--included__cHWdw {
  background-color: var(--sk-color-green-550);
}

.IconPackState_main--notIncluded__DtOrF {
  background-color: var(--sk-color-grey-200);
}

}
@layer base {
  .FeaturePackCell_featurePackCell__lonIj {
  border: none;
  border-bottom: 1px solid var(--jt-subscriptionTable-border-color);
  background-color: var(--sk-color-white);
}

.FeaturePackCell_text__XivnQ {
  display: flex;
  flex-direction: column;
}

.FeaturePackCell_bold__1492d {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

@media (min-width: 64rem) {
  .FeaturePackCell_featurePackCell__lonIj {
    border: none;
  }
}

}
@layer base {
  .RecruitAdInsert_main__YNrG1 {
  --backgroundImage: unset;

  position: relative;
  margin-bottom: var(--sk-space-80);
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-400);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -200px -200px;
  color: var(--sk-color-white);
  text-shadow: var(--sk-color-purple-500) 0 0 0.625rem;
}

.RecruitAdInsert_content__Fy34I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-32);
}

.RecruitAdInsert_cta__IG_mz {
  flex-shrink: 0;
  text-shadow: none;
}

.RecruitAdInsert_text__HQl8G {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .RecruitAdInsert_main__YNrG1 {
    padding: var(--sk-space-56) var(--sk-space-72);
  }

  .RecruitAdInsert_content__Fy34I {
    flex-direction: row;
    gap: var(--sk-space-56);
  }
}

}
@layer base {
  .LanguageDropdown_triggerButton__ot69F {
  border-color: transparent;
  outline: 1px solid var(--color-grey--200);
  background: var(--color-white);
  color: var(--color-grey--600);
}

}
@layer base {
  .ActionBar_column__tAwEt {
  margin-top: 0;
  margin-bottom: 0;
}

.ActionBar_actionsContainer__33tF1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacer-300) 0;
  gap: var(--spacer-200);
}

.ActionBar_buttonsContainer__A2jG_ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-200);
}

}
@layer base {
  .NewLanguage_container__c_93_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.NewLanguage_buttonsContainer__oW0BS {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (min-width: 48rem) {
  .NewLanguage_container__c_93_ {
    padding: var(--spacer-1000);
  }

  .NewLanguage_heading__agdLl {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  .PagesStatus_carousel__ZQbjL {
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.PagesStatus_item__WoPIe {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.PagesStatus_item__first__XG5X3 {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.PagesStatus_item__last__IQnUC {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.PagesStatus_item__WoPIe:hover::after,
.PagesStatus_item__active__4ThSu::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.PagesStatus_item__WoPIe:hover::after {
  background: var(--color-grey--200);
}

.PagesStatus_item__active__4ThSu::after,
.PagesStatus_item__active__4ThSu:hover::after {
  background: var(--color-black);
}

.PagesStatus_separator__nWorZ {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.PagesStatus_date__d_EsK {
  color: var(--color-grey--600);
}

}
@layer base {
  .MenuDropdown_dropdown__iM4YR {
  width: 100%;
}

.MenuDropdown_triggerButton__YJCKf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.MenuDropdown_triggerButton__selected__MR7xG {
  background-color: var(--color-black);
  color: var(--color-white);
}

.MenuDropdown_triggerButton__YJCKf:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.MenuDropdown_triggerButton__YJCKf:hover::before {
  background-color: transparent;
}

.MenuDropdown_triggerButton__YJCKf:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.MenuDropdown_triggerButton__selected__MR7xG:hover {
  background-color: var(--color-grey--800);
}

.MenuDropdown_triggerButton__selected__MR7xG:focus {
  background-color: var(--color-black);
}

.MenuDropdown_triggerButtonInitial__Nenpk {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.MenuDropdown_triggerButton__YJCKf:not(.MenuDropdown_triggerButton__selected__MR7xG):hover .MenuDropdown_triggerButtonInitial__Nenpk {
  background-color: var(--color-grey--200);
}

.MenuDropdown_triggerButtonText__ICZyM {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuDropdown_triggerButtonArrowWithTag__JsOD8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.MenuDropdown_triggerButtonText___hidden__BxAoZ {
  display: none;
}

.MenuDropdown_dropdownUserMenuItemLanguage__9Sune {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownUserMenuItemCurrentLocale__ER_l2 {
  text-transform: uppercase;
}

.MenuDropdown_dropdownConsumptionMenuItem__5FgLt {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.MenuDropdown_dropdownConsumptionMenuItemLink__j_YkA {
  border-top: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownConsumptionMenuItemValue___FOWd {
  font-weight: var(--semiBold);
}

.MenuDropdown_newTag__4GObg {
  margin-left: var(--spacer-200);
}

}
